@import "~bootstrap";

html {
    position: relative;
    min-height: 100%;
}
body {
    font-family: spartan;
    color: #444;
}

.spacer{
    margin-top: 30px;
    margin-bottom: 30px;
}

.jumbotron-img {
    background: rgb(237,116,88);
    background: linear-gradient(302deg, rgba(237,116,88,1) 16%, rgba(51,61,81,1) 97%);
    color: #fff;
}

.q-container {
    border-top-right-radius: .25rem;
    border-top-left-radius: .25rem;
    background-color: #efefee;
}

.q-container-show {
    border-top-right-radius: .25rem;
    border-top-left-radius: .25rem;
    background-color: #ED7458 ;
}

.q-container img, .q-container-show img {
    border: 2px solid #fff;
    border-radius: 50%;
}

.q-display {
    background: #fff;
    border-radius: .25rem;
}
.q-title-show {
    text-transform: uppercase;
    font-size: 1.3rem;
    color: #fff;
}
.q-title {
    text-transform: uppercase;
    color: #444;
}

.q-title:hover {
    color: #2B2B2B;
}

.q-title h2 {
    font-size: 1.3rem;
}

.q-display-response {
    background: #333D51;
    color: #fff;
}

.answer-link:hover .magic-wand {
    transform: rotate(20deg);
}

.vote-arrows {
    font-size: 1.5rem;
}

.vote-arrows span {
    font-size: 1rem;
}

.vote-arrows a {
    color: #444;
}

.vote-up:hover {
    color: #3D9970;
}
.vote-down:hover {
    color: #FF4136;
}

.btn-question {
    color: #FFFFFF;
    background-color: #ED7458;
    border-color: #D45B3F;
}

.btn-question:hover,
.btn-question:focus,
.btn-question:active,
.btn-question.active,
.open .dropdown-toggle.btn-question {
    color: #FFFFFF;
    background-color: #D45B3F;
    border-color: #D45B3F;
}

.btn-question:active,
.btn-question.active,
.open .dropdown-toggle.btn-question {
    background-image: none;
}

.btn-question.disabled,
.btn-question[disabled],
fieldset[disabled] .btn-question,
.btn-question.disabled:hover,
.btn-question[disabled]:hover,
fieldset[disabled] .btn-question:hover,
.btn-question.disabled:focus,
.btn-question[disabled]:focus,
fieldset[disabled] .btn-question:focus,
.btn-question.disabled:active,
.btn-question[disabled]:active,
fieldset[disabled] .btn-question:active,
.btn-question.disabled.active,
.btn-question[disabled].active,
fieldset[disabled] .btn-question.active {
    background-color: #ED7458;
    border-color: #D45B3F;
}

.btn-question .badge {
    color: #ED7458;
    background-color: #FFFFFF;
}

footer {
    background-color: #efefee;
    padding-bottom: 20px;
    text-align: center;
    margin-bottom: 0;
    /*position: absolute;*/
    /*bottom: 0;*/
    /*width: 100%;*/
    /*height: 200px; !* Set the fixed height of the footer here *!*/
    /*line-height: 120px; !* Vertically center the text there *!*/
}

/* Des added */
.colCenterAlign {
    text-align: center;
}
/* end Des added */

.search-preview {
    position: absolute;
    z-index: 100;
    width: 100%;
    top: 44px;
}
.search-preview img {
    width: 50px;
}